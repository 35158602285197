<template>
    <div class="wrapper flex flex-col h-full pt-8">
        <svg class="w-full max-w-[146px] mx-auto mb-6" width="146" height="45" fill="none"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354 109">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M64.7 63.7v-52h18.8v96.7C62.3 88.2 41 67.9 19.7 47.7v52.4H.1V0c21.1 21.1 43.3 42.7 64.5 63.7h.1ZM93 15.8v-4.1h54.2v4.1H93Zm60.6 0v-4.1h19.3v4.1h-19.3Zm-60.7 7.8v-4.1h54.2v4.1H92.9Zm0 7.9v-4.1h54.2v4.1H92.9Zm0 15.1v-4.1h54.2v4.1H92.9Zm0 8v-4.1h54.2v4.1H92.9Zm0 7.3v-4.1h54.2v4.1H92.9Zm60.7-38.3v-4.1h19.2v4.1h-19.2Zm0 15v-4.1h19.2v4.1h-19.2Zm0-7.1v-4.1h19.2v4.1h-19.2Zm0 15.1v-4.1h19.2v4.1h-19.2Zm-.1 8v-4.1h19.2v4.1h-19.2Zm0 7.3v-4.1h19.2v4.1h-19.2ZM92.9 38.4v-4.1h19.9v4.1H92.9Zm0 30.9v-4.1h19.9v4.1H92.9Zm0 7.9v-4.1h19.9v4.1H92.9Zm0 8.3v-4.1h54.2v4.1H92.9Zm0 7.4v-4.1h54.2v4.1H92.9Zm0 7.4v-4.1h54.2v4.1H92.9Zm60.6-30.8v-4.1h19.2v4.1h-19.2Zm0 7.5v-4.1h19.2V77h-19.2Zm-.1 8.5v-4.1h45.8v4.1h-45.8Zm.1 7.5v-4.1h45.8V93h-45.8Zm-.1 7.4v-4.1h45.8v4.1h-45.8Zm94.5-95.5 45.1 95.8h-21.7l-8.9-19.8h-29.8l-9.2 19.6h-21.8l46.3-95.6Zm-7.3 57.8h14.8L248 47.9l-7.3 14.8h-.1ZM341 51.1c7.7 6 12.6 11.6 12.6 21.8 0 10.2-3.9 17.4-11.3 22.5-13 8.8-31.9 5.7-46.8 5.7V11.9c12.1-1.8 27.4-1.5 38.8 3.2 5.2 2.2 12.4 10.8 12.7 16.8.4 7.6-2.4 12.7-6 19.1v.1Zm-25.5-21.6v16.4h3.4c3.3 0 8.1-2 8.7-8 .4-4.6-4.8-8.6-9.2-8.4h-2.9Zm-.1 33.9V83c.3-.7 17.6 2.7 18-10.2.3-6.5-7-9.6-13-9.4h-5Z"
                fill="#FFED00" />
        </svg>
        <div class="text-center">
            <p class="text-center text-white text-[16px] leading-[1.6] mb-1.5">Ditt resultat:</p>
            <p v-if="!isCurrentUserFirst" class="text-[36px] leading-[1.1] font-bold text-white text-center">{{
                finalScore }}/{{
                    questions.length }}
            </p>
            <p v-if="isCurrentUserFirst"
                class="text-[36px] leading-[1.1] font-medium text-white text-center flex flex-row justify-center items-center gap-2">
                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5002 5.5L7.00024 1M18.0561 14L1 13M15.5113 22.2321L5.00023 26" stroke="#fff"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
                {{ finalScore }}/{{ questions.length }}
                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5559 5.5L12.0559 1M1 14L18.0561 13M3.54482 22.2321L14.0559 26" stroke="#fff"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </p>

            <p class="text-white text-[18px] leading-[1.6] font-medium mb-1.5">{{ elapsedTime }}</p>
            <p v-if="!isCurrentUserFirst" class="text-center text-white text-[18px] leading-[1.6] mb-8">
                Tyvärr räckte det inte hela vägen…
            </p>

            <p v-if="isCurrentUserFirst" class="text-center text-white text-[18px] leading-[1.6] mb-8">Snyggt! Det blir
                många julklappar för dig!
            </p>
        </div>

        <div class="px-5 mb-6">
            <div class="bg-blue rounded-2xl py-6 px-5">
                <p class="text-white text-[16px] leading-[1.6] mb-2">TOPP TIO</p>
                <ol class="list-decimal list-inside" v-if="leaderboard">
                    <li class="text-white text-[16px] leading-[1.6] mb-2 last:mb-0"
                        :class="{ 'text-[18px] leading-[1.6] font-medium': participant.isCurrentUser }"
                        v-for="participant in leaderboard" :key="participant.id">
                        {{ participant.name }}
                    </li>
                </ol>
            </div>
        </div>

    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { questions } from '../data/questions'
import Button from './Button.vue'
import logo from '@assets/img/logotype.png'
import content from '../data/content'

export default {
    name: 'ResultScreen',
    components: {
        Button,
    },
    computed: {
        logo() {
            return logo
        },
        contentData() {
            return content
        }
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const elapsedTime = computed(() => store.getters.elapsedTime)
        const finalScore = computed(() => store.getters.finalScore)
        const leaderboard = computed(() => store.getters.leaderboard)
        const isCurrentUserFirst = computed(() => store.getters.isCurrentUserFirst)

        onMounted(() => {
            if (store.getters.answers.length < questions.length) {
                console.log('User has not completed the quiz, return to frontpage')
                router.push({ name: 'start' })
            }
        })

        return {
            elapsedTime,
            finalScore,
            leaderboard,
            isCurrentUserFirst,
            questions
        }

    }
}
</script>
