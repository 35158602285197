<template>
    <div class="px-5 py-8 wrapper">
        <svg class="w-full max-w-[146px] mx-auto mb-6" fill="none" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 354 109">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M64.7 63.7v-52h18.8v96.7C62.3 88.2 41 67.9 19.7 47.7v52.4H.1V0c21.1 21.1 43.3 42.7 64.5 63.7h.1ZM93 15.8v-4.1h54.2v4.1H93Zm60.6 0v-4.1h19.3v4.1h-19.3Zm-60.7 7.8v-4.1h54.2v4.1H92.9Zm0 7.9v-4.1h54.2v4.1H92.9Zm0 15.1v-4.1h54.2v4.1H92.9Zm0 8v-4.1h54.2v4.1H92.9Zm0 7.3v-4.1h54.2v4.1H92.9Zm60.7-38.3v-4.1h19.2v4.1h-19.2Zm0 15v-4.1h19.2v4.1h-19.2Zm0-7.1v-4.1h19.2v4.1h-19.2Zm0 15.1v-4.1h19.2v4.1h-19.2Zm-.1 8v-4.1h19.2v4.1h-19.2Zm0 7.3v-4.1h19.2v4.1h-19.2ZM92.9 38.4v-4.1h19.9v4.1H92.9Zm0 30.9v-4.1h19.9v4.1H92.9Zm0 7.9v-4.1h19.9v4.1H92.9Zm0 8.3v-4.1h54.2v4.1H92.9Zm0 7.4v-4.1h54.2v4.1H92.9Zm0 7.4v-4.1h54.2v4.1H92.9Zm60.6-30.8v-4.1h19.2v4.1h-19.2Zm0 7.5v-4.1h19.2V77h-19.2Zm-.1 8.5v-4.1h45.8v4.1h-45.8Zm.1 7.5v-4.1h45.8V93h-45.8Zm-.1 7.4v-4.1h45.8v4.1h-45.8Zm94.5-95.5 45.1 95.8h-21.7l-8.9-19.8h-29.8l-9.2 19.6h-21.8l46.3-95.6Zm-7.3 57.8h14.8L248 47.9l-7.3 14.8h-.1ZM341 51.1c7.7 6 12.6 11.6 12.6 21.8 0 10.2-3.9 17.4-11.3 22.5-13 8.8-31.9 5.7-46.8 5.7V11.9c12.1-1.8 27.4-1.5 38.8 3.2 5.2 2.2 12.4 10.8 12.7 16.8.4 7.6-2.4 12.7-6 19.1v.1Zm-25.5-21.6v16.4h3.4c3.3 0 8.1-2 8.7-8 .4-4.6-4.8-8.6-9.2-8.4h-2.9Zm-.1 33.9V83c.3-.7 17.6 2.7 18-10.2.3-6.5-7-9.6-13-9.4h-5Z"
                fill="#FFED00" />
        </svg>
        <div>
            <h1 class="heading-xl text-white font-bold text-center mb-4">Proffs på julen?</h1>
            <p class="body-md text-white text-center mb-8">Du kanske äter köttbullar, ägghalvor och lutfisk på julafton
                - men vet du vad de heter på engelska? Eller vilken jullåt som är allas favorit?</p>
        </div>

        <div class="bg-blue p-6 rounded-2xl -rotate-[4deg] mb-8">
            <p class="body-lg text-white text-center">Utmana alla på festen - och se vem som är proffs - och vem som är
                Grinchen!</p>
        </div>
        <button @click="startQuiz" class="button">
            {{ contentData.startScreen.buttonText }}
            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.2633 7.53826H0.399475V5.89987H17.2632L12.9273 1.56397L14.0859 0.405457L20.3995 6.71908L14.0859 13.0327L12.9273 11.8742L17.2633 7.53826Z"
                    fill="#000" />
            </svg>
        </button>
    </div>

</template>

<script>
import logo from '@assets/img/logotype.png'
import content from '../data/content'

export default {
    name: 'StartScreen',
    methods: {
        startQuiz() {
            this.$store.dispatch('updateTransition', 'slide-right')
            this.$router.push({ name: 'questions' })
        }
    },
    computed: {
        logo() {
            return logo
        },
        contentData() {
            return content
        }
    },
}
</script>
