// import q1 from '@assets/img/01.svg'
// import q2 from '@assets/img/02.svg'
// import q3 from '@assets/img/03.svg'
// import q4 from '@assets/img/04.svg'
// import q5 from '@assets/img/05.svg'

export const questions = [
    {
        id: 0,
        question: 'Vad är den engelska översättningen av julrätten “lutfisk”?',
        options: [
            { text: 'Stockfish', isCorrect: false },
            { text: 'Lutefish', isCorrect: true },
            { text: 'Dried codfish', isCorrect: false },
            { text: 'Pickled herring', isCorrect: false }
        ]
    },
    {
        id: 1,
        question: 'Vad heter tomtens släde på finska?',
        options: [
            { text: 'Joulukelkka', isCorrect: false },
            { text: 'Tonttusleigh', isCorrect: false },
            { text: 'Joulupukin reki', isCorrect: true },
            { text: 'Pukkikelkka', isCorrect: false }
        ]
    },
    {
        id: 2,
        question: 'Vilket land anses vara hem till den första moderna julgranen?',
        options: [
            { text: 'Tyskland', isCorrect: true },
            { text: 'England', isCorrect: false },
            { text: 'Danmark', isCorrect: false },
            { text: 'Nederländerna', isCorrect: false }
        ]
    },
    {
        id: 3,
        question: 'Vilket av dessa julgodis innehåller mandel som en huvudingrediens?',
        options: [
            { text: 'Knäck', isCorrect: false },
            { text: 'Fudge', isCorrect: false },
            { text: 'Marsipan', isCorrect: true },
            { text: 'Polkagris', isCorrect: false }
        ]
    },
    {
        id: 4,
        question:
            'Vilket land är känt för traditionen att kasta skor på julafton för att se om man kommer att gifta sig?',
        options: [
            { text: 'Polen', isCorrect: false },
            { text: 'Tjeckien', isCorrect: true },
            { text: 'Ungern', isCorrect: false },
            { text: 'Österrike', isCorrect: false }
        ]
    },
    {
        id: 5,
        question:
            'I vilket land är det tradition att fira jul med KFC (Kentucky Fried Chicken) istället för en vanlig julmåltid?',
        options: [
            { text: 'USA', isCorrect: false },
            { text: 'Japan', isCorrect: true },
            { text: 'Sydafrika', isCorrect: false },
            { text: 'Australien', isCorrect: false }
        ]
    },
    {
        id: 6,
        question: 'Vilken känd julfilm har repliken: “Keep the change, ya filthy animal”?',
        options: [
            { text: 'Die Hard', isCorrect: false },
            { text: 'Love Actually', isCorrect: false },
            { text: 'Ensam Hemma', isCorrect: true },
            { text: 'The Grinch', isCorrect: false }
        ]
    },
    {
        id: 7,
        question:
            'I vilket land är det vanligt att fira jul med att ge bort en sockerskulptur av en häst?',
        options: [
            { text: 'Finland', isCorrect: false },
            { text: 'Danmark', isCorrect: true },
            { text: 'Norge', isCorrect: false },
            { text: 'Island', isCorrect: false }
        ]
    },
    {
        id: 8,
        question: 'Vilken jullåt är den mest streamade genom tiderna på Spotify?',
        options: [
            { text: 'Jingle Bell Rock', isCorrect: false },
            { text: 'Last Christmas', isCorrect: false },
            { text: 'All I Want for Christmas Is You', isCorrect: true },
            { text: 'Fairytale of New York', isCorrect: false }
        ]
    },
    {
        id: 9,
        question:
            'I vilket land finns en tradition att hålla julafton som en fest med ett enormt julmåltid som inte får avslutas förrän man har ätit 12 rätter?',
        options: [
            { text: 'Polen', isCorrect: true },
            { text: 'Grekland', isCorrect: false },
            { text: 'Mexico', isCorrect: false },
            { text: 'Sverige', isCorrect: false }
        ]
    },
    {
        id: 10,
        question:
            'Vilken svensk julfilm handlar om en katastrofal julafton hos en dysfunktionell familj?',
        options: [
            { text: 'Julkalendern', isCorrect: false },
            { text: 'Kan du vissla Johanna?', isCorrect: false },
            { text: 'Tomten är far till alla barnen', isCorrect: true },
            { text: 'Sunes jul', isCorrect: false }
        ]
    },
    {
        id: 11,
        question:
            'I vilket land är det tradition att fira jul genom att “slå” sina vänner med rispinnar som ett sätt att välkomna det nya året?',
        options: [
            { text: 'Japan', isCorrect: false },
            { text: 'Argentina', isCorrect: false },
            { text: 'Portugal', isCorrect: true },
            { text: 'Finland', isCorrect: false }
        ]
    },
    {
        id: 12,
        question:
            'I vilket land är det tradition att barnen får en julklapp från “La Befana”, en vänlig häxa, istället för Santa Claus?',
        options: [
            { text: 'Italien', isCorrect: true },
            { text: 'Grekland', isCorrect: false },
            { text: 'Ryssland', isCorrect: false },
            { text: 'Sverige', isCorrect: false }
        ]
    }
]
