<template>
    <button :disabled="disabled" :class="{ 'is-disabled': disabled }"
        class="button flex flex-row items-center justify-center">
        {{ text }}
        <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.2633 7.53826H0.399475V5.89987H17.2632L12.9273 1.56397L14.0859 0.405457L20.3995 6.71908L14.0859 13.0327L12.9273 11.8742L17.2633 7.53826Z"
                fill="#000" />
        </svg>
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        text: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
.button {
    @apply transition-opacity duration-300;
}

.is-disabled {
    @apply opacity-50;
}
</style>